export default {
    en: {
        confirm: "Confirm",
        cancel: "Cancel",
        titleCredit: "Credits",
        buttonMessage: "Send Message",
        messageSending: "Ready to send message to [@@]?"
    },
    zh: {
        confirm: "确认",
        cancel: "取消",
        titleCredit: "选课积分",
        buttonMessage: "发消息",
        messageSending: "将给[@@]发消息吗？"
    }
}