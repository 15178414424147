<template>
<div class="flex flex-col">
    <van-cell-group inset class="mb-8 py-8 overflow-y-scroll">
        <div class="text-center text-xs text-gray-300 mb-4" v-if="!onlyView">{{peerText}}</div>
        <div class="flex" :class="{'flex-row-reverse': mes.self}" v-for="(mes, index) in history" :key="index">
            <div class="w-12 h-12 m-2 overflow-hidden rounded flex-grow-0 flex-shrink-0 border cursor-pointer" @click="goMessage(mes.senderId, mes.senderName)">
                <img :src="Env.userPath + mes.senderIcon" />
            </div>
            <div class="border border-gray-200 rounded m-2 p-2 mb-4" @click="mes.showTime = !mes.showTime">
                <span v-if="mes.type === 9" class="text-sm" v-html="mes.content"></span>
                <span v-else class="text-sm">{{mes.content}}</span>
                <span v-if="mes.showTime" class="text-xs block mt-2 text-gray-300 text-right">{{mes.timeText}}</span>
            </div>
            <van-button v-if="mes.showTime" class="m-2 flex-grow-0 flex-shrink-0" size="mini" plain type="danger" @click="deleteMessage(mes, index)">{{lang[langCode].buttonDelete}}</van-button>
        </div>
        <div v-if="history.length === 0" class="text-center text-xs text-gray-300">{{lang[langCode].textNone}}</div>
        <div v-else class="text-center text-xs text-gray-300">{{lang[langCode].textLatest}}</div>
    </van-cell-group>
    <van-cell-group inset class="mb-4 flex-grow-0 flex-shrink-0" v-if="!onlyView">
        <van-field v-model="message" rows="3" :label="lang[langCode].textHere" type="textarea" maxlength="300" :placeholder="lang[langCode].textPlace" show-word-limit>
            <template #button>
                <van-button size="mini" type="primary" @click="send()">{{lang[langCode].buttonSend}}</van-button>
            </template>
        </van-field>
    </van-cell-group>
    <div class="flex flex-col items-center">
        <van-button class="h-10 w-1/2" type="danger" block @click="deleteAll()">删除所有系统信息</van-button>
    </div>
    <profile-viewer ref="viewProfileRef" :lang-code="langCode" />
</div>
</template>

<script>
import lang from "@/langs/MessageSenderLang.js";
import {
    ref,
    computed
} from "vue";
import {
    useStore
} from "vuex";
import {
    useRouter
} from "vue-router";
import {
    CellGroup,
    Field,
    Button,
    Toast,
    Dialog
} from "vant";
import Env from "@/logics/Envs.js";
import FormatMessage from "@/logics/FormatMessage.js";
import ListMessage from "@/asyncs/ListMessage.js";
import AddMessage from "@/asyncs/AddMessage.js";
import DeleteMessage from "@/asyncs/DeleteMessage.js";
import ProfileViewer from "@/components/dialog/ProfileViewer.vue";

export default {
    components: {
        [CellGroup.name]: CellGroup,
        [Field.name]: Field,
        [Button.name]: Button,
        ProfileViewer
    },
    props: {
        langCode: {
            type: String,
            default: "zh"
        },
        peerId: String,
        senderId: String,
        readerUserType: String
    },
    setup(props) {
        const store = useStore();
        const router = useRouter();
        const message = ref("");
        const history = ref([]);
        const peerName = ref("");
        store.commit("setMessageHistory", history);
        const onlyView = ref(props.peerId === "onlyView");
        const viewProfileRef = ref(null);

        const asyncInit = async function () {
            const listMessage = await ListMessage(props.peerId, onlyView.value);
            listMessage[0].forEach(function (m) {
                FormatMessage(m, props.readerUserType, props.senderId);
            });
            history.value = listMessage[0];
            if (history.value.length) {
                peerName.value = history.value[0].senderName;
            }
        };
        asyncInit();

        const send = async function () {
            const addMessage = await AddMessage(props.peerId, message.value.trim());
            if (addMessage[1]) {
                Toast.fail(addMessage[1]);
            } else {
                message.value = "";
                const newMessage = addMessage[0];
                FormatMessage(newMessage, props.readerUserType, props.senderId);
                history.value.push(newMessage);
            }
        };
        const deleteMessage = async function (m, index) {
            try {
                await Dialog.confirm({
                    title: lang[props.langCode].deleteTitle,
                    confirmButtonColor: "#ee0a24",
                    confirmButtonText: lang[props.langCode].confirm,
                    cancelButtonText: lang[props.langCode].cancel
                });
            } catch (e) {
                console.log(e);
                return;
            }
            const error = await DeleteMessage(m.id);
            if (error) {
                Toast.fail(error);
            } else {
                history.value.splice(index, 1);
            }
        };
        const goMessage = async function (id, name) {
            if (!id) {
                alert("This is a system message");
                return;
            }
            if (onlyView.value) {
                try {
                    await Dialog.confirm({
                        title: "将给[" + name + "]发消息吗？",
                        confirmButtonColor: "#4187F2"
                    });
                    router.push("/message/" + id);
                } catch (e) {
                    console.log(e);
                    return;
                }
            } else {
                viewProfileRef.value.loadUser(id);
            }
        };

        const deleteAll = async function () {
            try {
                await Dialog.confirm({
                    title: "确定删除所有消息吗？",
                    confirmButtonColor: "#ee0a24",
                    confirmButtonText: lang[props.langCode].confirm,
                    cancelButtonText: lang[props.langCode].cancel
                });
            } catch (e) {
                console.log(e);
                return;
            }
            const error = await DeleteMessage(history.value[0].id, true);
            if (error) {
                Toast.fail(error);
            } else {
                history.value.splice(0, history.value.length);
            }
        };

        return {
            lang,
            Env,
            message,
            history,
            send,
            deleteMessage,
            peerName,
            onlyView,
            goMessage,
            peerText: computed(() => lang[props.langCode].textPeer.replace("@@", peerName.value)),
            viewProfileRef,
            deleteAll
        };
    }
}
</script>
