<template>
<van-cell center>
    <template #title>
        <van-image :src="Env.userPath + user.profile.icon" round fit="cover" width="6rem" height="6rem" />
    </template>
    <template #value>
        <div class="pr-6 text-left">
            <van-tag class="mb-2" color="#6665a3" size="large">{{UserTypes[user.userType].name[langCode]}}</van-tag>
            <br />
            <van-tag class="mr-3 mb-1" :color="TagTypes.personal.color" v-for="(tag, index) in user.meta.tags.personal" :key="index">{{tag}}</van-tag>
            <div v-if="showSystemTag">
                <van-tag class="mr-3 mb-1" :color="TagTypes.system.color" v-for="(tag, index) in user.meta.tags.system" :key="index">{{tag}}</van-tag>
            </div>
        </div>
    </template>
</van-cell>
<van-cell :title="titleName" :label="user.profile.showName">
    <template #value>
        <div class="pr-6 text-left" :class="{'h-32': shortBio, 'overflow-y-scroll': shortBio}">
            {{user.profile.bio}}
        </div>
    </template>
</van-cell>
<van-cell v-if="showCredit" :title="lang[langCode].titleCredit">
    <template #value>
        <div class="text-left">
            {{user.credit}}
        </div>
    </template>
</van-cell>
<van-cell>
    <template #title>
        <div class="flex items-center mb-2" v-for="(contact, index) in user.profile.contact" :key="index">
            <img class="w-6" :src="Env.displayPath + Contacts[contact.type].icon" />
            <span class="text-xs text-gray-600 ml-2">{{contact.value}}</span>
        </div>
        <div class="flex items-center mb-2 cursor-pointer" @click="goMessage()">
            <img class="w-6" :src="Env.displayPath + 'contact_message.svg'" />
            <span class="text-xs text-gray-600 ml-2">{{lang[langCode].buttonMessage}}</span>
        </div>
    </template>
</van-cell>
</template>

<script>
import lang from "@/langs/ProfileCellLang.js";
import {
    computed
} from "vue";
import {
    useRouter
} from "vue-router";
import {
    useStore
} from "vuex";
import {
    Image as VanImage,
    Tag,
    Cell,
    Dialog
} from "vant";
import Env from "@/logics/Envs.js";
import UserTypes from "@/static_values/StaticUserTypes.js";
import Contacts from "@/static_values/StaticContactMethods.js";
import TagTypes from "@/static_values/StaticTagTypes.js";

export default {
    components: {
        [VanImage.name]: VanImage,
        [Tag.name]: Tag,
        [Cell.name]: Cell
    },
    props: {
        user: Object,
        langCode: {
            type: String,
            default: "zh"
        },
        shortBio: Boolean,
        showCredit: Boolean,
        showSystemTag: Boolean
    },
    setup(props) {
        const titleName = computed(() => props.user.profile.firstName + " " + props.user.profile.lastName);
        const router = useRouter();
        const store = useStore();

        const goMessage = async function () {
            try {
                await Dialog.confirm({
                    title: lang[store.state.lang].messageSending.replace("@@", props.user.profile.showName),
                    confirmButtonColor: "#4187F2",
                    confirmButtonText: lang[store.state.lang].confirm,
                    cancelButtonText: lang[store.state.lang].cancel
                });
                if (props.user.userId === store.state.user.userId) {
                    router.push("/message/onlyView");
                } else {
                    router.push("/message/" + props.user.userId);
                }
            } catch (e) {
                console.log(e);
                return;
            }
        };

        return {
            lang,
            titleName,
            Env,
            Contacts,
            UserTypes,
            TagTypes,
            goMessage
        };
    }
}
</script>
