<template>
    <div class="pt-32 flex justify-center h-screen">
        <message-sender v-if="ready" class="w-full md:w-1/2" :peerId="peerId" :senderId="senderId"
            :readerUserType="readerUserType" :lang-code="langCode" />
    </div>
</template>

<script>
import lang from "@/langs/PageMessageLang.js";
import {
    ref,
    computed,
    onMounted
} from "vue";
import {
    useStore
} from "vuex";
import {
    useRoute,
    useRouter
} from "vue-router";
import MessageSender from "@/components/MessageSender.vue";
import GetSession from "@/asyncs/GetSession.js";

export default {
    components: {
        MessageSender
    },
    setup() {
        const store = useStore();
        store.commit("showBack");
        store.commit("showMenu");
        store.commit("hideNoti");
        const route = useRoute();
        const router = useRouter();
        const ready = ref(false);
        const peerId = ref(route.params.peerId);
        onMounted(() => {
            GetSession({
                store,
                router,
                route
            }, async function () {
                if (route.params.peerId === "onlyView") {
                    store.commit("setPageTitle", lang[store.state.lang].titleMy);
                } else {
                    store.commit("setPageTitle", lang[store.state.lang].titleSend);
                }
                ready.value = true;
            });
        });
        return {
            langCode: computed(() => store.state.lang),
            peerId,
            ready,
            senderId: computed(() => store.state.user.userId),
            readerUserType: computed(() => store.state.user.userType)
        };
    }
}
</script>
