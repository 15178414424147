import axios from "axios";
import Env from "@/logics/Envs.js";

export default async function (reader, content) {
    try {
        const toSend = new FormData();
        toSend.append("reader", reader);
        toSend.append("content", content);

        const res = await axios.post(Env.apiPath + "addMessage", toSend);
        return [res.data.message, res.data.error];
    } catch (err) {
        return [null, err];
    }
}