export default {
    cell: {
        type: "cell",
        icon: "contact_cell.svg",
        name: "Cell"
    },
    mail: {
        type: "mail",
        icon: "contact_mail.svg",
        name: "Email"
    },
    we: {
        type: "we",
        icon: "contact_wechat.svg",
        name: "微信"
    },
    ig: {
        type: "ig",
        icon: "contact_ins.svg",
        name: "Instagram"
    },
    other: {
        type: "other",
        icon: "contact_other.svg",
        name: "Other"
    }
}