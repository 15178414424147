<template>
<van-popup class="w-screen md:w-1/2 pt-4" v-model:show="show" closeable>
    <div class="my-h">
        <profile-cell :user="user" :lang-code="langCode" :showSystemTag="showSystemTag" />
    </div>
</van-popup>
</template>

<script>
import ProfileCell from "@/components/ProfileCell.vue";
import ViewUser from "@/asyncs/ViewUser.js";

import {
    ref
} from "vue";
import {
    Popup
} from "vant";

export default {
    components: {
        [Popup.name]: Popup,
        ProfileCell
    },
    props: {
        langCode: {
            type: String,
            default: "zh"
        },
        showSystemTag: {
            type: Boolean,
            default: false
        }
    },
    setup() {
        const user = ref(null);
        const show = ref(false);
        const showUser = function (userProfile) {
            user.value = userProfile;
            show.value = true;
        };
        const loadUser = async function (userId) {
            user.value = await ViewUser(userId);
            if (!user.value) {
                return;
            }
            show.value = true;
        };
        return {
            show,
            showUser,
            loadUser,
            user
        };
    }
}
</script>

<style scoped>
.my-h {
    height: 50vh;
}
</style>
