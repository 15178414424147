import axios from "axios";
import Env from "@/logics/Envs.js";

export default async function (peerId, onlyView) {
    try {
        const params = { peerId, onlyView };
        const res = await axios.get(Env.apiPath + "listMessage", { params });
        return [res.data.list, res.data.error];
    } catch (err) {
        return [null, err];
    }
}