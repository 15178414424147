export default {
    en: {
        confirm: "Confirm",
        cancel: "Cancel",
        textPeer: "Conversation with @@",
        textNone: "No Messages",
        textLatest: "Latest",
        textHere: "Message",
        textPlace: "Please input message text here",
        buttonDelete: "Delete",
        buttonSend: "Send",
        deleteTitle: "Sure to delete this message?"
    },
    zh: {
        confirm: "确认",
        cancel: "取消",
        textPeer: "与 @@ 的对话",
        textNone: "无消息",
        textLatest: "最新",
        textHere: "留言",
        textPlace: "请输入留言",
        buttonDelete: "删除",
        buttonSend: "发送",
        deleteTitle: "确定删除此消息吗？"
    }
}