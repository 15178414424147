import axios from "axios";
import Env from "@/logics/Envs.js";

export default async function (messageId, allSystem) {
    try {
        const params = { messageId, allSystem };
        const res = await axios.get(Env.apiPath + "deleteMessage", { params });
        return res.data.error;
    } catch (err) {
        return err;
    }
}